import './App.css';
import {Navbar, Nav, NavDropdown, Form, FormControl, Button} from "react-bootstrap";
import {useState} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

function App() {
  const [show, setShow] = useState(true);


  return (
    <div className="App">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand>Dominic-Dev</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/games">Games</Nav.Link>
            <Nav.Link href="/blog">Blog</Nav.Link>
            <Nav.Link href="/impressum">Impressum :D</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>



      <Router>
        <div>
          <Switch>
            <Route path="/impressum">
              <About />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/games">
              <Games />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>

    </div>
  );
}

function Home() {
  return <h2>Home</h2>;
}


function Blog() {
  return <h2>Blog</h2>;
}

function Games() {
  return (
  <div>
    <h2>Games</h2>
    <iframe
        src="https://towerdefence.d-heil.de/" title="Towerdefence"
        width={970}
        height={660}
     ></iframe>
  </div>
  );
}

function About() {
  return <h2>Impressum</h2>;
}

function Topics() {
  let match = useRouteMatch();

  return (
      <div>
        <h2>Topics</h2>

        <ul>
          <li>
            <Link to={`${match.url}/components`}>Components</Link>
          </li>
          <li>
            <Link to={`${match.url}/props-v-state`}>
              Props v. State
            </Link>
          </li>
        </ul>

        {/* The Topics page has its own <Switch> with more routes
          that build on the /topics URL path. You can think of the
          2nd <Route> here as an "index" page for all topics, or
          the page that is shown when no topic is selected */}
        <Switch>
          <Route path={`${match.path}/:topicId`}>
            <Topic />
          </Route>
          <Route path={match.path}>
            <h3>Please select a topic.</h3>
          </Route>
        </Switch>
      </div>
  );
}

function Topic() {
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
}


export default App;
